import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IAvatar, IAvatarResponse, IFile } from '../../layouts/avatar-layout/avatar.model';
import { ISearchData } from '../../../admin-goal-management/layouts/admin-goal-management-layout';
import { PaginatorUiComponent } from 'src/app/modules/common-components/paginator-ui/paginator-ui.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DataService } from 'src/app/services/data.service';
import { take } from 'rxjs/operators';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { BUTTON_EVENTS, DIALOG_TYPES } from 'src/app/constants';
import { AvatarService } from '../../services/avatar.service';

export interface IAvatarOutputResponse{
  avatar: IAvatar;
  gender?: string;
  pagination: any;
}

@Component({
  selector: 'app-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnChanges, OnInit {
  @ViewChild('paginator') paginator: PaginatorUiComponent;
  @ViewChild('paginatorDisabled') paginatorDisabled: PaginatorUiComponent;

  @Input() enabledAvatars: IAvatarResponse;
  @Input() disabledAvatars: IAvatarResponse;
  @Output() paginationEvent = new EventEmitter();
  @Output() paginationDisbaledEvent = new EventEmitter();
  @Output() addAvatarEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter<IAvatarOutputResponse>();
  @Output() enableDisableEvent = new EventEmitter<IAvatarOutputResponse>();
  @Output() updateEvent = new EventEmitter<any>();

  public defaultAvatars: IAvatar[] = [];
  public prefixPathForPredefined: string = '';
  public prefixPathForClientCreated: string = '';
  public paginatorDataEnabled = { totalDataCnt: 10 };
  public paginatorDataDisabled = { totalDataCnt: 10 };
  public selectedAvatar: any = null;
  public isAdmin = false;

  private readonly PAGINATION_EVENT_GETDATA: string = 'getData';

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(public dialog: MatDialog, private weds: WaitErrorDialogsService, private ds: DataService, private avatarService: AvatarService) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    if(location.pathname.includes('admin')){
      this.isAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.enabledAvatars && this.enabledAvatars?.data) {
      this.prefixPathForPredefined = this.enabledAvatars.data.prefixPathForPredefined;
      this.prefixPathForClientCreated = this.enabledAvatars.data.prefixPathForClientCreated;
      this.paginatorDataEnabled.totalDataCnt = this.enabledAvatars.data.total;
      this.paginator?.calculatePageNumbers(this.paginatorDataEnabled);
    }
    if(changes.disabledAvatars && this.disabledAvatars?.data) {
      this.paginatorDataDisabled.totalDataCnt = this.disabledAvatars.data.total;
      this.paginatorDisabled?.calculatePageNumbers(this.paginatorDataDisabled);
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public async onAvatarClick(avatar: IAvatar) {
    this.selectedAvatar = avatar;
    const imageUrl = this.getAvatarUrl(avatar);
    const base64Image = await this.fetchImageAsBase64(imageUrl);
    this.onFileSelected(base64Image, null, true);
  }

  public reset() {
    this.defaultAvatars = [];
  }

  public getAvatarUrl(avatar: IAvatar): string {
    const prefix = avatar.avatarCategory === 'predefined' ? this.prefixPathForPredefined : this.prefixPathForClientCreated;
    const url = `${prefix}/${avatar.avatarImgFile}`;
    return url;
  }

  public onPaginationEvent(event) {
    if (event.type == this.PAGINATION_EVENT_GETDATA) {
      this.paginationEvent.emit(this.createPayload(this.paginator));
    }
  }

  public onPaginationEventDisabled(event) {
    if (event.type == this.PAGINATION_EVENT_GETDATA) {
      this.paginationDisbaledEvent.emit(this.createPayload(this.paginatorDisabled));
    }
  }

  public onFileSelectedFromUser(event: any, fileInput: HTMLInputElement) {
    this.avatarService.handleFileSelection(event, fileInput);
    this.avatarService.avatarImageUrlForImgCropper$.pipe(take(1)).subscribe((imageURL: string) => {
      if (imageURL) {
        this.onFileSelected(imageURL, fileInput);
      }
    });
  }

  public onFileSelected(event: any, fileInput: HTMLInputElement, isImageURL = false) {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      disableClose: true,
    });

    dialogRef.componentInstance.imageURL = event;
    if(isImageURL) {
      dialogRef.componentInstance.selectedAvatar = this.selectedAvatar;
    } else {
      dialogRef.componentInstance.selectedFile = fileInput;
    }
    dialogRef.componentInstance.showAvatarDeleteBtn = this.selectedAvatar?.isEnabled === 0;
    dialogRef.componentInstance.deleteEvent.pipe(take(1)).subscribe((data: any) => {
      this.handleAction(data, 'delete');
    });
    dialogRef.componentInstance.enableDisableEvent.pipe(take(1)).subscribe((data: any) => {
      this.handleAction(data, 'toggle');
    });
    dialogRef.componentInstance.updateEvent.pipe(take(1)).subscribe((data: IAvatar) => {
      this.handleAction(data, 'update');
    });
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      console.log('onEvent', data);
      if (data?.file) {
        this.uploadAvatar(data);
      }
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
      this.clearSelectedFile(fileInput);
      this.selectedAvatar = null;
    });
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private handleAction(avatar: IAvatar, actionType: 'delete' | 'update' | 'toggle') {
    this.selectedAvatar = avatar;
    let dialogData: any;

    switch (actionType) {
      case 'delete':
        dialogData = this.getDialogData({
          title: 'Delete Avatar?',
          message: 'Are you sure you want to delete this avatar?',
          btnTitle: 'Delete',
          btn2Title: 'Cancel',
          message2: 'This CANNOT be undone.'
        });
        dialogData = {
          ...dialogData,
          icon: 'warning_open',
          warnButton: true,
          btnIconName: 'close',
          noGrow: true
        }
        break;

      case 'update':
        dialogData = this.getDialogData({
          title: 'Confirm Update',
          message: 'Are you sure you want to update this avatar?',
          btnTitle: 'Update',
          btn2Title: 'Cancel',
        });
        break;

      case 'toggle':
        this.selectedAvatar.isEnabled = this.selectedAvatar.isEnabled ? 0 : 1;
        const toggleTitle = avatar.isEnabled ? 'Enable' : 'Disable';
        dialogData = this.getDialogData({
          title: `${toggleTitle} Avatar?`,
          message: `Are you sure you want to ${toggleTitle.toLowerCase()} this avatar?`,
          btnTitle: toggleTitle,
          btn2Title: 'Cancel',
        });

        if(!avatar.isEnabled) {
          dialogData.message2 = 'All users who have selected this avatar will revert back to gender neutral avatar.';
        }
        break;
    }

    this.openDialog(dialogData, actionType);
  }

  private getDialogData({ title, message, btnTitle, btn2Title, message2 }: { title: string, message: string, btnTitle: string, btn2Title: string, message2?: string }) {
    return {
      type: DIALOG_TYPES.GENERIC,
      title: title,
      message: message,
      btnTitle: btnTitle,
      btn2Title: btn2Title,
      message2: message2
    };
  }

  private openDialog(dialogData: any, actionType: 'delete' | 'update' | 'toggle') {

    const dialogRef = this.dialog.open(SharedDialogComponent, {
      panelClass: 'shared-dialog',
      disableClose: false
    });

    dialogRef.componentInstance.data = dialogData;

    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      this.dialog.closeAll();
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        switch (actionType) {
          case 'delete':
            this.deleteEvent.emit({
              avatar: this.selectedAvatar,
              pagination: { enabled: this.paginator.pageNumber, disabled: this.paginatorDisabled.pageNumber }
            });
            break;

          case 'update':
            const data: { avatar: IAvatar, gender: string, pagination: any } = {
              avatar: this.selectedAvatar,
              gender: this.selectedAvatar.gender,
              pagination: { enabled: this.paginator.pageNumber, disabled: this.paginatorDisabled.pageNumber }
            }
            this.updateEvent.emit(data);
            break;

          case 'toggle':
            this.enableDisableEvent.emit({
              avatar: this.selectedAvatar,
              pagination: { enabled: this.paginator.pageNumber, disabled: this.paginatorDisabled.pageNumber }
            });
            break;
        }
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private createPayload(paginator: PaginatorUiComponent) {
    let payload: ISearchData  = {};
    payload.limit = paginator?.limit;
    payload.pageNo = paginator.pageNumber;
    return payload;
  }

  public clearSelectedFile(fileInput: HTMLInputElement) {
    if(fileInput) {
      fileInput.value = '';
    }
  }

  private uploadAvatar(data: { file: IFile, gender: string, avatar: IAvatar, pagination: any }) {
    data.avatar = this.selectedAvatar;
    data.pagination = { enabled: this.paginator.pageNumber, disabled: this.paginatorDisabled.pageNumber };
    if(data?.avatar) {
      this.updateEvent.emit(data);
    } else {
      this.addAvatarEvent.emit(data);
    }
  }

  private async fetchImageAsBase64(imageUrl: string): Promise<string> {
    try {
      const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
      const response = await fetch(imageUrl, {
        headers: {
          'Accept': '*/*',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        },
      });
      const blob = await response.blob();
      dialogRef.close();
      return await this.blobToBase64(blob);
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
