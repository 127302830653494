export enum BadgeType {
  ONE_TIME = 'one-time',
  REPEATABLE = 'repeatable',
  LEVELS = 'multi-level'
}

export enum LogicType {
  FILTER = 'filter',
  AGGREGATE = 'aggregate'
}

export enum Mode {
  EDIT = 'Edit',
  NEW = 'New',
  VIEW = 'View'
}

export enum BadgeState {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled'
}

export enum SelectControlType {
  ENTITY = 'entity',
  FIELD = 'field',
  OPERATOR = 'operator'
}

export enum NotificationPrompt {
  IMMEDIATE = 'Immediate',
  DELAYED = 'Delayed'
}


