import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CoralogixRum } from '@coralogix/browser';

interface IEnvironment { 
  production: boolean,
  coralogix_public_key: string
}


if (environment.production) {
  let environment = 'production';
  const appDomains = ["https://prismforce.com", "https://pfuat.xyz", "https://prismforce.ai", "https://prismtechnology.xyz"]
  if (window) {
    let url = new URL(window.location.href);
    environment = (url.hostname || 'Development').split('.').reverse().slice(0, 2).reverse().join('.');
  }

  enableProdMode();
  initSentry(environment, appDomains);
  embedWhatfix();
} else {
  //This will move to the "if" block after testing on lower envs
  initCoralogix(environment);
}

function embedWhatfix() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://cdn.whatfix.com/prod/03bdd35b-c8ae-445e-9964-173d750fc504/initiator/initiator.nocache.js';
  document.head.appendChild(script);
}

 function initCoralogix(environment: IEnvironment) {
    CoralogixRum.init({
      public_key: environment.coralogix_public_key,
      application: 'SmsLocalTest',
      version: '1.0',
      coralogixDomain: 'AP1'
    });
  }

function initSentry(environment: string, appDomains: string[]) {
  Sentry.init({
    dsn: "https://f39c843071074f109d46e3b3a2a42a81@o828115.ingest.sentry.io/5811526",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
      
      // Ignore non-standard errors 
      const exceptionMsgArray = [
        'Non-Error exception captured with keys',
        'ResizeObserver loop completed with undelivered notifications.'
      ]
      if (exceptionMsgArray.some(msg => event?.['message']?.includes(msg) || event?.['title']?.includes(msg))) {
        return null;
      }

      // Ignore errors from vendor files and anonymous errors which might indicate non-app related code
      if (event.exception?.values?.some(value =>
        value.stacktrace?.frames?.some(frame =>
          frame?.filename?.includes('/vendor.') || frame?.filename === "\u003Canonymous\u003E"
        ))) {
        return null;
      }

      return event;
    },
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: appDomains,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false
      })
    ],
    environment: environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
