import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalService } from 'src/app/services/goal.service';
import { GoalStatus } from '../goals-track-list/goals-track-list.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { take } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { IGoalResponse } from '../../my-goals-layout';
import { Location } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { GoalsCheckInComponent } from '../goals-check-in/goals-check-in.component';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { BUTTON_EVENTS, DIALOG_TYPES } from 'src/app/constants';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { IDialogConfig } from 'src/app/modules/team-skill-approvals/team-skill-approvals-v2/team-skill-approvals.model';
import { AddGoalComponent } from 'src/app/modules/admin/admin-goal-management/components/add-goal/add-goal.component';
import { IMyGrowthResponse, PayloadKey } from '../my-growth-plans-card';
import { IDP_GROWTH_PLAN_STATUS } from 'src/app/constants/idp-filter.constants';
import { ToastService } from 'src/app/modules/common-components/toast/toast.service';
import { ICommentReplyApiResponse, ICommentResponse, IGoalDetailApiResponse, IUserInfo } from '.';

@Component({
  selector: 'app-goals-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit {

  @Input() goal: IGoal = null;
  @Input() isTeamGoals: boolean = false;
  @Input() isArchived: boolean = false;

  @Output() goalApproved: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalRejected: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalArchived: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalUnArchived: EventEmitter<IGoal> = new EventEmitter();

  public goalActionData = [
    { icon: 'edit', name: 'Edit' },
    { icon: 'inventory_2', name: 'Archive' }
  ];

  public goalStatusColors: { [key in GoalStatus]: string } = this._goalService.getGoalStatusColors(false);
  public goalStatusBGColors: { [key in GoalStatus]: string } = this._goalService.getGoalStatusBGColors();

  public readonly PROGRESS_STATUS = this._goalService.progressStatus;

  public profileImageUrl: string = null;
  public userName: string = null;
  public myGrowthPlanData: IMyGrowthResponse;

  public comments: any[] = [];
  public newComment: string = '';
  public replyText: string = '';
  public selectedCommentForReply: any = null;
  public page = 1;
  public limit = 10;
  public userId: string;
  public clientId: string;
  public goalId: string;
  public usersInfo: { [id: string]: IUserInfo } = {};

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public _goalService: GoalService,
    private _us: UtilitiesService,
    private ds: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private weds: WaitErrorDialogsService,
    private location: Location,
    private toast: ToastService
  ) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.clientId = this.ds.client.clientId;
    this.userId = this.ds.user.userId;
     this.goalId = this.route.snapshot.queryParams['goalId'];

    this.getGrowthPlanData();
    this.getGoalDetails();
    this.fetchComments();
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public navigateBack() {
    this.location.back();
  }

  public getColorForGoalStatus(goalStatus: string) {
    return this.goalStatusColors[goalStatus];
  }

  public getBGColorForGoalStatus(goalStatus: string) {
    return this.goalStatusBGColors[goalStatus];
  }

  public getShortName(name: string) {
    return this._us.getShortName(name);
  }

  public onCheckInClick() {
    const dialogRef = this.dialog.open(GoalsCheckInComponent, { width: '600px', panelClass: 'browse-skills' });
    dialogRef.componentInstance.data = this.goal;
    const onEvent = dialogRef.componentInstance.onCheckIn.pipe(take(1)).subscribe((data: any) => {
      dialogRef.close();
      if (data.event === 'checkIn') {
        console.log('Clicked on checkIn')
      }
    })
  }

  public approveGoal() {
    this.goalApproved.emit(this.goal);
  }

  public rejectGoal() {
    this.goalRejected.emit(this.goal);
  }

  public editGoal() {
    this.editCurrentGoal(this.goal);
  }

  public archiveGoal() {
    this.goalArchived.emit(this.goal);
  }

  public unArchive() {
    this.unArchiveSelected(this.goal);
  }

  public onGoalActionClick(event) {
    if(event.name === 'Edit') {
      this.editAction(this.goal);
    } else {
      this.archiveAction();
    }
  }

  public addComment() {
    if (this.newComment.trim()) {
      const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
      const url = API_ENDPOINT.ADD_COMMENTS(this.clientId, this.goal.goalId);
      const payload =  {
        data: {
          content: this.newComment,
          userId: this.userId
        }
      };

      this.ds.postApi(url, payload).pipe(take(1)).subscribe((response: ICommentResponse) => {
          this.weds.closeDialog(dialogRef);
          this.comments.unshift(response.comment);
          this.newComment = '';
        }
      );
    }
  }

  public replyToComment(comment: any) {
    if (this.replyText.trim()) {
      const url = API_ENDPOINT.REPLY_COMMENTS(this.clientId, comment.commentId);
      const payload =  {
        data: {
          content: this.replyText,
          userId: this.userId
        }
      };

      this.ds.postApi(url, payload).pipe(take(1)).subscribe(
        (response) => {
          comment.replies = comment.replies || [];
          comment.replies.push(response);
          this.replyText = '';
          this.selectedCommentForReply = null;
          this.fetchComments();
        }
      );
    }
  }

  public onTabChange(event: any) {

  }

  getProfileInfo(userId: string): IUserInfo {
    return this.usersInfo[userId];
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private getClientIdAndUserId(): { clientId: string, userId: string } {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId;
    return { clientId, userId };
  }

  private transformGoals(goals: any[]): any[] {
    return goals?.map(goal => ({
      ...goal,
      icon: this._goalService.goalTypeIconsMap[goal?.type?.toLowerCase()],
      selected: false,
    }));
  }

  private getDefaultPayload(filters: any[] = []): any {
    return {
      filters,
      searchKey: "",
      page: 1,
      limit: 10,
    };
  }

  private getAllAssignedGoals(filter: any) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const { clientId } = this.getClientIdAndUserId();
    const payload = this.getDefaultPayload();
    payload.filters.push(filter);

    const isTeamGoals = this.isTeamGoals || this.route.snapshot.queryParams['isTeamGoals'];

    if(isTeamGoals) {
      payload.userIdForTeamGoal = this.route.snapshot.queryParams['userId'];
    }

    const ENDPOINT = this.isTeamGoals ? API_ENDPOINT.GET_GOALS_TEAM_GOALS(clientId): API_ENDPOINT.GET_GOALS_MYGOALS(clientId) ;

    this.ds.careerPrismDataPostApi(ENDPOINT, payload).pipe(take(1)).subscribe((res: IGoalDetailApiResponse) => {
      this.weds.closeDialog(dialogRef);
      if (res.ok) {
        console.log(res);
        this.goal = this.transformGoals(res.data?.goals)[0];
        this.profileImageUrl = res.data?.userInfo?.[0]?.profileImg;
        this.userName = res.data?.userInfo?.[0]?.name;
      }
    });
  }

  private editAction(goal: IGoal) {
     this.editCurrentGoal(goal);
  }

  private editCurrentGoal(editGoalObj?: IGoal): any {
    let dialogRef = this.dialog.open(AddGoalComponent, {
      width: window.innerWidth < 900 ? (0.95 * window.innerWidth) + 'px' : '800px',
      height: window.innerWidth < 900 ? (0.9 * window.innerHeight) + 'px' : '580px',
      panelClass: 'notification-dialog',
      disableClose: true
    });

    dialogRef.componentInstance.title = 'Edit Goal';
    dialogRef.componentInstance.isMyGoals = true;
    dialogRef.componentInstance.editGoalObj = editGoalObj;
    dialogRef.componentInstance.growthPlanData = this.myGrowthPlanData as any;
    dialogRef.componentInstance.refresh.pipe(take(1)).subscribe((res) => {
      this.getGoalDetails();
    });
    dialogRef.componentInstance.onEvent.pipe(take(1)).subscribe((res) => {
        dialogRef.close();
    });
  }

  private archiveAction() {
    const dialogArchive = {
      title: 'Archive goal',
      message: `Are you sure you want to archive this goal?`,
      btnTitle: 'Archive',
      btn2Title: 'Cancel',
    };

    this.confirmArchive(dialogArchive);
  }

  public unArchiveSelected(goal: IGoal) {
    const dialogArchive = {
      title: 'Unarchive goal',
      message: `Are you sure you want to unarchive this goal?`,
      btnTitle: 'UnArchive',
      btn2Title: 'Cancel',
    }
    this.confirmUnarchiveAndUpdate(dialogArchive, [goal.goalId]);
  }

  private confirmUnarchiveAndUpdate(dialogData: IDialogConfig, goalId: string[]) {
    const dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });

    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      ...dialogData
    };

    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        this.updateGoalToUnArchive(goalId);
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private confirmArchive(dialogData: IDialogConfig) {
    const dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });

    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      ...dialogData
    };

    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        this.updateAssignedGoals(this.goal.goalId, true);
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private updateGoalToUnArchive(goalIds: string[]) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const { clientId, userId } = this.getClientIdAndUserId();
    const payload = {
      data: {
        goalIds,
        isArchived: false
      },
      userId: userId,
    };

    this.ds.careerPrismDataPostApi(API_ENDPOINT.UPDATE_RECOMMENDED_GOALS(clientId), payload).pipe(take(1)).subscribe((res: IGoalResponse) => {
      if (res.ok) {
        this.weds.closeDialog(dialogRef);
        const msg = 'Unarchive goal success';
        const desc = 'You have successfully unarchived the goal.';
        this.toast.showToast({ type: 'success', msg: msg, desc: desc });
        this.getGoalDetails();
      }
    });
  }

  private updateAssignedGoals(goalId: string, isArchived = false) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const { clientId, userId } = this.getClientIdAndUserId();
    const payload = {
      data: {
        goalId,
        isArchived
      },
      userId: userId,
    };

    this.ds.careerPrismDataPostApi(API_ENDPOINT.UPDATE_GOALS_MYGOALS(clientId), payload).pipe(take(1)).subscribe((res: IGoalResponse) => {
      this.weds.closeDialog(dialogRef);
      if (res.ok) {
        const msg = 'Aarchive goal success';
        const desc = 'You have successfully archived the goal.';
        this.toast.showToast({ type: 'success', msg: msg, desc: desc });
        this.getGoalDetails();
      }
    });
  }

  private getGrowthPlanData() {
    const { clientId } = this.getClientIdAndUserId();
    const payload = this.getDefaultPayload([{ key: PayloadKey.STATUS, value: IDP_GROWTH_PLAN_STATUS.ACTIVE }]);
    this._goalService.getGrowthPlans(clientId, payload).pipe(take(1)).subscribe((data: IMyGrowthResponse) => {
      if (data) {
        this.myGrowthPlanData = data;
      }
    });
  }

  private getGoalDetails() {
    this.getAllAssignedGoals({ key: 'goalId', value: this.goalId });
  }

  private fetchComments() {
    const url = API_ENDPOINT.GET_COMMENTS(this.clientId, this.goalId, this.page, this.limit);
    this.ds.careerPrismDataGetApi(url).pipe(take(1)).subscribe(
      (response: ICommentReplyApiResponse) => {
        this.comments = response.reply.data.comments;
        this.usersInfo = response.reply.data.usersInfo;
      }
    );
  }

  private selectCommentForReply(comment: any) {
    this.selectedCommentForReply = comment;
  }
}
