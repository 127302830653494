import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-aliases',
  templateUrl: './aliases.component.html',
  styleUrls: ['./aliases.component.scss']
})
export class AliasesComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  aliasForm: FormGroup;
  aliases: any[] = [
    { title: 'Skills & Competencies', key: 'skillsAndCompetenciesAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'High Demand Skill', key: 'highDemandSkillAlias', type: 'text', validators: [], icon: 'trending_up' },
    { title: 'Skill Group', key: 'skillGroupAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Specialization', key: 'specializationsAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'] },
    { title: 'Primary Specialization', key: 'primarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
    { title: 'Secondary Specialization', key: 'secondarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
    { title: 'Tertiary Specialization', key: 'tertiarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
    { title: 'Core Mandatory', key: 'coreMandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Mandatory', key: 'mandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Optional', key: 'optionalAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Cond. Mandatory', key: 'conditionalMandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Service', key: 'servicesAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['services'] },
    { title: 'Skill Cluster', key: 'skillClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['skill-clusters'] },
    { title: 'Location Cluster', key: 'locationClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['location-clusters'] },
    { title: 'Demand Units', key: 'demandUnitAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['demand-units'] },
    // { title: 'All Demand Unit', key: 'allDemandUnitAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['all-demand-units'] },
    { title: 'SKU', key: 'skuAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['sku'] },
    { title: 'Designation', key: 'designationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designations'] },
    { title: 'Designation ID', key: 'extDesignationIdAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designations'], checkValue: [{ key: 'mapExternalIdToDesignations', values: ['Yes'] }] },
    { title: 'Designation Level', key: 'designationLevelAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-levels'] },
    { title: 'Designation Path', key: 'designationPathAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-paths'] },
    { title: 'Designation Cluster', key: 'designationClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-clusters'] },
    { title: 'Role', key: 'roleAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Skill Recommendation', key: 'skillRecommendationAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Designation Path Bookmark', key: 'bookmarkAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Upload Resume (My Skills Page)', key: 'uploadResumeAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'My Team', key: 'myTeamAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Team Details', key: 'teamDetailsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Manage Learnings', key: 'manageLearningsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Endorsements', key: 'endorsementsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Exit Rating (Specializations)', key: 'exitRatingAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Rating', key: 'ratingAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'My Learnings', key: 'myLearningsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'My Learning Initial Status', key: 'myLearningInitialStatusAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Skill Type', key: 'skillTypeAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Must Complete Learning Element', key: 'mustCompleteLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Completed Learning Element', key: 'completedLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Optional Learning Element', key: 'optionalLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'My Goals', key: 'myGoalsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Team Goals', key: 'teamGoalsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
    { title: 'Growth Plan', key: 'developmentPlanAlias', type: 'text', validators: [], icon: 'settings_suggest' },

  ];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.aliasForm = this.fb.group({});
  }

  ngOnInit(): void {

  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    const allowedLevelOfSpecialization = Number(this.client.smsSettings.levelOfSpecializationCount);

    const dynamicLevelOfSpecializationAliases = [...new Array(allowedLevelOfSpecialization + 1 || 0)]
      .map((_, index) => ({
        title: `Level Of Specialization Alias ${index}`,
        key: `levelOfSpecialization${index}`,
        type: 'text',
        validators: [],
        icon: 'settings_suggest',
      }))
      .filter((newAlias) => !this.aliases.some((existingAlias) => existingAlias.key === newAlias.key));

    this.aliases = [...this.aliases, ...dynamicLevelOfSpecializationAliases];

    this.aliases.map((s) => {
      s.showControl = true;
      (s.allowIf || []).map((key) => {
        if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false;
      });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.aliasForm.contains(item.key) ? this.aliasForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.aliasForm.contains(s.key)) this.aliasForm.removeControl(s.key);
      if (s.showControl) this.aliasForm.addControl(s.key, new FormControl({ value: this.client.smsSettings[s.key], disabled: !this.canEdit }, s.validators));
    });

  }

  saveAliases() {
    if (this.aliasForm.invalid) return;
    let d = this.aliasForm.value;
    d.type = 'aliases';
    this.onSave.emit(d);
  }

}
