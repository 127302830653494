import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { SkillUtilitiesService } from '../../../services/skill-utilities.service';

@Component({
  selector: 'app-skill-details-modal',
  templateUrl: './skill-details-modal.component.html',
  styleUrls: ['./skill-details-modal.component.scss']
})
export class SkillDetailsModalComponent implements OnInit {

  @Input() skillData: any;
  @Output() onEvent = new EventEmitter();

  public settings: any;
  public proficiencyLevels = [];
  public hideHierarchies = [false, false];
  public highDemandSkillAlias: string = '';

  private smsSettings: any;
  private tooltipValue = [
    "Basic Knowledge / Classroom training",
    "Basic hands-on knowledge / Limited project experience",
    "Good knowledge of skill / Ready for client deployment with some support",
    "Very good knowledge of skill / Ready for client deployment without support",
    "Expert in skill / Can train others"
  ];

  constructor(
    private ds: DataService,
    private skillUtils: SkillUtilitiesService,
  ) { }

  ngOnInit(): void {
    this.highDemandSkillAlias = this.ds.dictionary.highDemandSkill;
    this.smsSettings = this.ds.client.smsSettings;
    const skillRating = this.smsSettings.skillRating;
    const totalProficiencyLevels: number = skillRating === 'Stars 1-5' ? 5 : 4;
    for (let i = 1; i <= totalProficiencyLevels; i++) {
      this.proficiencyLevels.push(this.smsSettings[`skillToolTipLabel${i}`])
    }
    this.settings = this.smsSettings.categoryWiseSettings.find(
      (item: any) => item.categoryId == this.skillData.skillCategory.categoryId
    );
    this.hideHierarchies[0] = this.settings.hide2ndLevelHierarchy === SMS_OPTIONS.YES;
    this.hideHierarchies[1] = this.settings.hide3rdLevelHierarchy === SMS_OPTIONS.YES;
  }

  public getProficiencyData(proficiency, level: number) {
    if (proficiency && proficiency[`l${level}description`]) {
      return proficiency[`l${level}description`];
    } else if (this.smsSettings[`configureSkillsTooltip${level}`]) {
      return this.smsSettings[`configureSkillsTooltip${level}`];
    } else {
      return this.tooltipValue[level - 1];
    }
  }

  public closeDialog() {
    this.onEvent.emit({ status: 'close' })
  }

  get isHighDemandSkill(): boolean {
    return this.skillData?.isHighDemandSkill === SMS_OPTIONS.YES;
  }

  get isHighDemandSkillEnabled(): boolean {
    return this.skillUtils.checkIsHighDemandSkillTaggingEnabled;
  }
}
