import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable} from 'rxjs';
import { Client, IDictionary } from '../models/client.model';
import { User } from '../models/user.model';
import { DataShareService } from './data-share.service';
import { map } from 'rxjs/operators';
import { SMS_OPTIONS } from '../constants';
import { SKILL_ARRAY, SKILL_TREE } from '../modules/my-skills/browse-skills/browse-skills-v4/browse-skills.model';
import { IndexedDBUtilitiesService } from './indexed-db-utilities.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  devMode = "dev";
  user: User;
  client: Client;
  features: any;
  url = "https://" + window.location.hostname + "/prismapi/";
  rmsUrl = "https://" + window.location.hostname + "/rms/v1/api/";
  prismSearchUrl = "https://" + window.location.hostname + "/prism-search-api/"
  rmsXAPIKey = '9f6d6b50-bf0b-11ed-afa1-0242ac120002';
  smsInternalXAPIKey: string = 'c791b80a856ecc6058d04e202851553989b76a7a9e1548a993719de948f910bdfbc5b5ce63c5f2d46c7e7a140055967222a85f63da9144f2b34b48d6b9c7ad98';
  previousRoute: string = '';
  selectedSkill: any;
  selectedSkillCluster: any;
  currentAdminClientId: string;
  currentUserData: any;
  maxModifiedDt = "";
  maxSpecModifiedDt = "";
  skillHierarchy: any[];
  skillCategories: any[];
  skillItems: any[];
  subTeamStack = [];
  manageLearningsData: any;
  getClientCache: any = {};
  navInfo: any;//for inter component navigation
  smsCache: any = {};//for caching filter data
  form: any = {};
  mySkillForm = new BehaviorSubject<boolean>(true);
  dictionary: IDictionary;
  treatmentMap;
  defaultMatchScoreLimit: any = {
    min: 0.7,
    max: 1
  }
  allSkillsSaved = true;
  maxSkillApprovalLevel : number;
  experienceLimit: any = {
    min: 0.1,
    max: 35
  }
  profileProgressMap = {};
  restrictedSkillItemIds: string[];
  defaultAliasDictionary = {
    "skillsAndCompetencies": "Skills & Competencies",
    'specializations': 'Specialization',
    'mandatory': 'Mandatory',
    'optional': 'Optional',
    'demandUnit': 'Demand Unit',
    'services': 'Service',
    'sku': 'SKU',
    'skillGroup': 'Skill Group',
    'conditionalMandatory': 'Conditional Mandatory',
    'coreMandatory': 'Core Mandatory',
    'secondarySpecialization': 'Secondary',
    'primarySpecialization': 'Primary',
    'tertiarySpecialization': 'Tertiary',
    'role': 'Role',
    'designation': 'Designation',
    'extDesignationId': 'Designation ID',
    'designationLevel': 'Designation Level',
    'designationPath': 'Designation Path',
    'designationCluster': 'Designation Cluster',
    'skillCluster': 'Skill Cluster',
    'locationCluster': 'Location Cluster',
    'bookmark': 'Bookmark',
    'skillRecommendation': 'Skill Recommendation',
    'uploadResume': 'Upload Resume',
    'myTeam': 'My Team',
    'teamDetails': 'Team Details',
    'manageLearnings': 'Manage Learnings',
    'exitRating': "Exit Rating",
    'skillAdditionRequests': 'Skill Addition Requests',
    'rating': 'Rating',
    'myLearningInitialStatus': 'Not Started',
    'endorsements': 'Endorsements',
    'myLearnings': 'My Learnings',
    'skillType': 'Skill Type',
    'highDemandSkill': 'In-demand Skill'
  };

  eventEmitter = () => new EventEmitter<number>();
  pageNumberUpdated = this.eventEmitter();
  offsetUpdated = this.eventEmitter();
  limitChanged = this.eventEmitter();
  updatePageNumber = (pageNumber: number = 0) => this.pageNumberUpdated.emit(pageNumber);
  updateOffset = (offset: number = 0) => this.offsetUpdated.emit(offset);
  updateLimit = () => this.limitChanged.emit();

  constructor(
      private httpClient: HttpClient,
      private dataShare: DataShareService,
      private indexedDBUtils: IndexedDBUtilitiesService,
    ) {
    this.resetAliases();
    this.loadLocalData();
    this.updateAliasesForMenuItems(this.client);
    this.readSMSData();
  }

  onLogout() {
    this.dataShare.setLogoutEmitter();
    this.resetAliases();
    this.indexedDBUtils.deleteIndexedDB();
    this.clearLocalData();
  }

  loadLocalData() {
    try { this.client = new Client().deserialize(JSON.parse(localStorage.getItem("client"))); } catch (err) { }
    try {
      this.user = new User().deserialize(JSON.parse(localStorage.getItem("user")));
      this.profileProgressMap[this.user.userId] = this.user.profileProgress;
      if (!this.user.role.globalAdmin) {
        this.currentAdminClientId = this.client.clientId;
      } else {
        this.currentAdminClientId = JSON.parse(localStorage.getItem("currentClient")).clientId;
      }
    } catch (err) { }
    try { this.features = JSON.parse(localStorage.getItem("features")); } catch (err) { }
  }
  updateAliasesForMenuItems(client: Client) {
    this.resetAliases();
    try {
      for (let key in client.smsSettings) if (key.search(/Alias$/) > 0 && client.smsSettings[key]) this.dictionary[key.replace(/Alias$/, '')] = client.smsSettings[key];
      for (let key in this.dictionary) {
        if ((this.features.admin[key] || {}).menuName) this.features.admin[key].menuName = this.dictionary[key];
        if ((this.features.sms[key] || {}).menuName) {
          if ((this.features.sms[key].menuName || '').search(/specialization/i) >= 0) this.features.sms[key].menuName = this.features.sms[key].menuName.replace(/specialization/i, this.dictionary.specializations);

          if (key === 'myTeam') {
            for (let menuKey in this.features.sms) {
              if (this.features.sms[menuKey].menuItem === "My Team") {
                this.features.sms[menuKey].menuItem = this.dictionary.myTeam;
                if ((this.features.sms[menuKey].menuName || '') == 'Team Details') {
                  this.features.sms[menuKey].menuName = this.dictionary.teamDetails;
                } else {
                  this.features.sms[menuKey].menuName = this.dictionary[menuKey] || this.features.sms[menuKey].menuName;
                }
              }
            }
          } else {
            this.features.sms[key].menuName = this.dictionary[key];
          }

          (this.features.sms[key].subMenuItems || []).map((item) => { if (item.menuName == 'Specializations') item.menuName = this.dictionary.specializations + 's'; });
        }
      }
      if ((this.features.admin || {}).roles) this.features.admin.roles.menuName = this.dictionary.role + 's';
      if ((this.features.admin || {})['location-clusters']) this.features.admin['location-clusters'].menuName = this.dictionary.locationCluster + 's';
      if ((this.features.admin || {})['demand-units']) this.features.admin['demand-units'].menuName = this.dictionary.demandUnit + 's';
      if ((this.features.admin || {})['skill-clusters']) this.features.admin['skill-clusters'].menuName = this.dictionary.skillCluster;
      if ((this.features.admin || {})['services']) this.features.admin['services'].menuName = this.dictionary.services;
      if ((this.features.sms || {})['designation-paths']) this.features.sms['designation-paths'].menuName = this.dictionary.designationPath + 's';
      if ((this.features.sms || {})['skillRecommendation']) this.features.sms['skillRecommendation'].menuName = this.dictionary.skillRecommendation + 's';
      if ((this.features.sms || {})['mySkills']) this.features.sms['mySkills'].menuName = 'My ' + this.dictionary.skillsAndCompetencies;
      if (this.features?.sms?.teamSkillEndorsements) this.features.sms.teamSkillEndorsements.menuName = this.dictionary.endorsements;
      if ((this.features.sms || {})['dashboards']) {
        const {subMenuItems} = this.features.sms['dashboards'];
        if (subMenuItems?.length) {
          const {useSkillClusterBasedInsights,specializationMode} = client.smsSettings;
          const specName = (useSkillClusterBasedInsights === 'Yes' && specializationMode === 'Skill Cluster Based') ? this.dictionary.skillCluster : this.dictionary.specializations;
          const indexOfSpecialization = subMenuItems.findIndex(({menuName})=> menuName === 'Specializations');
          if (indexOfSpecialization > -1)
            subMenuItems[indexOfSpecialization].menuName = specName;
        }
      }
      if ((this.features.sms || {})['skillClusterMapping']) this.features.sms['skillClusterMapping'].menuName = this.dictionary.skillCluster + ' Mapping';
      if ((this.features.sms || {})['mySpecializations']) this.features.sms['mySpecializations'].menuName = 'My ' + this.dictionary.specializations + 's';
      if (this.features.sms?.myLearnings) {
        this.features.sms.myLearnings?.subMenuItems?.forEach(item => {
          item.menuName = item?.menuName.replace('Specialization', this.dictionary.specializations)
        })
      }
      if ((this.features.sms || {}).myLearningsBySpecialization) this.features.sms.myLearningsBySpecialization.menuName = this.features.sms.myLearningsBySpecialization.menuName?.replace('My Learnings', this.dictionary.myLearnings)?.replace('Specialization', this.dictionary.specializations);
      if ((this.features.sms || {}).myLearningsIndividualLearning) this.features.sms.myLearningsIndividualLearning.menuName = this.features.sms.myLearningsIndividualLearning.menuName?.replace('My Learnings', this.dictionary.myLearnings);
      if ((this.features.sms || {}).myLearningsOverview) this.features.sms.myLearningsOverview.menuName = this.features.sms.myLearningsOverview.menuName?.replace('My Learnings', this.dictionary.myLearnings)
      this.dataShare.setMenuItemAlias(this.dictionary);
    } catch (e) { }
  }
  resetAliases() {
    this.dictionary = JSON.parse(JSON.stringify(this.defaultAliasDictionary));
  }
  clearLocalData() {
    ["user", "client", 'features', 'token', 'slToken', 'smsSkillsData', 'smsSpecsData', 'smsCoursesData',
      'smsCache', 'designationPathWalkthroughCompleted', SKILL_TREE, SKILL_ARRAY,
      'onboardingComplete', 'isStaffingRoleModalShown'].forEach((key) => { localStorage.removeItem(key) });
    this.user = null;
    this.client = null;
    this.features = null;
    this.selectedSkill = null;
    this.currentAdminClientId = null;
    this.currentUserData = null;
    this.maxModifiedDt = "";
    this.skillHierarchy = null;
    this.skillCategories = null;
    this.skillItems = null;
    this.subTeamStack = [];
    this.manageLearningsData = null;
    this.allSkillsSaved = true;
    this.restrictedSkillItemIds = null;
    this.previousRoute = '';
    this.profileProgressMap = {};
  }
  saveUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.user = new User().deserialize(user);
    this.profileProgressMap[this.user.userId] = this.user.profileProgress;
  }
  updateUserProfileImg(profileImg) {
    try { let user = JSON.parse(localStorage.getItem("user")); user.profileImg = profileImg; localStorage.setItem("user", JSON.stringify(user)); } catch (err) { }
  }
  saveClient(client) {
    localStorage.setItem("client", JSON.stringify(client));
    this.client = new Client().deserialize(client);
    this.updateAliasesForMenuItems(this.client);
  }
  saveToken(token) {
    if (token) localStorage.setItem('token', token);
  }
  savePrismSearchToken(token: string) {
    if (token) localStorage.setItem('prismSearchToken', token);
  }
  saveSLToken(token) {
    if (token) localStorage.setItem('token', token);
    if (token) localStorage.setItem('slToken', token);
  }
  saveFeatures(features) {
    if (features) localStorage.setItem("features", JSON.stringify(features));
    this.features = features;
    this.updateAliasesForMenuItems(this.client);
  }

  autoLogin() {
    let token = "";
    try { token = localStorage.getItem("token"); } catch (err) { }
    if (token) {
      try { localStorage.removeItem('token'); } catch (err) { }
    }
    const payload = { token: token, timezoneOffset: new Date().getTimezoneOffset() };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
    return this.httpClient.post<JSON>(this.url + "autoLogin", payload, headers)
    .pipe(
      map((resp: any) => {
        localStorage.setItem("treatmentMap", JSON.stringify(resp?.treatmentMap || {}));
        this.dataShare.setOnBoardingStatus(resp?.user?.onboardingComplete || false);
        for (const key in resp?.treatmentMap) {
          if (resp?.treatmentMap[key] === 'T1' && token && resp?.status === 'Success' && resp?.client && resp?.client?.subDomain !== 'pf') {
            this.getUserData();
            break;
          }
        }
        return resp;
    }))
  }
  getUserData() {
    const payload = {
      getUserSkills: true,
      getUserCourses: true,
      getUserSpecializations: true,
    };
    const subscription = this.searchData('sms/v2/getUserInfo', payload)
    .subscribe(res => {
      this.cacheSMSData(res);
      subscription.unsubscribe();
    });
  }
  login(d) {
    d.timezoneOffset = new Date().getTimezoneOffset();
    return this.httpClient.post<JSON>(this.url + "login", d, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  forgotPassword(d) {
    return this.httpClient.post<JSON>(this.url + "forgotPassword", d, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  resetPassword(d) {
    return this.httpClient.post<JSON>(this.url + "resetPassword", d, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  logout() {
    return this.httpClient.post<JSON>(this.url + "logout", {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  //----------------------Search function-----------------------
  searchData<T>(endpoint: string, search: any): Observable<T> {
    search.maxModifiedDt = this.maxModifiedDt;
    return this.httpClient.post<T>(this.url + endpoint, search, { responseType: search.responseType, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  globalSearchData(endpoint, search) {
    search.maxModifiedDt = this.maxModifiedDt;
    const prismSearchToken = localStorage.getItem('prismSearchToken');
    return this.httpClient.post<JSON>( this.prismSearchUrl + endpoint, search, { responseType: search.responseType, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'prismsearchtoken': prismSearchToken}) });
  }
  postApi<T>(endpoint: string, payload: any): Observable<T> {
    return this.httpClient.post<T>(this.url + endpoint, payload , { headers: new HttpHeaders({ 'Content-Type': 'application/json'})});
  }
  //----------------------Saving data-----------------------
  saveData<T>(endpoint: string, data: any): Observable<T> {
    return this.httpClient.post<T>(this.url + endpoint, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  careerPrismDataGetApi(endpoint, payload?) {
    return this.httpClient.get<any>(this.url + endpoint, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache'}), params: payload });
  }
  careerPrismDataPostApi(endpoint, payload) {
    return this.httpClient.post<any>(this.url + endpoint, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  careerPrismDataPutApi(endpoint, payload) {
    return this.httpClient.put<any>(this.url + endpoint, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  careerPrismDataDeleteApi(endpoint) {
    return this.httpClient.delete<any>(this.url + endpoint, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  sendRMSPostRequest(endpoint, data) {
    return this.httpClient.post<any>(this.rmsUrl + endpoint, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': this.rmsXAPIKey }) });
  }
  sendRMSGetRequest(endpoint) {
    return this.httpClient.get<any>(this.rmsUrl + endpoint, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': this.rmsXAPIKey }) });
  }
  sendSMSInternalPostRequest(endpoint: string, payload) {
    return this.httpClient.post<any>(this.url + endpoint, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': this.smsInternalXAPIKey }) });
  }
  uploadRMSFile(endpoint, data) {
    return this.httpClient.post<any>(this.rmsUrl + endpoint, data, { headers: new HttpHeaders({ 'x-api-key': this.rmsXAPIKey }) });
  }
  uploadFiles(files, clientId, requiredTypes?: string[], maxFileSizeInMB?: number) : any {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let f = files[i];
      formData.append('file[]', f);
    }
    if (requiredTypes) formData.append("requiredTypes", requiredTypes.join( ','));
    if (maxFileSizeInMB) formData.append("maxFileSizeInMB", maxFileSizeInMB + '');
    formData.append("clientId", clientId);
    return this.httpClient.post(this.url + 'uploads', formData, { reportProgress: true, observe: 'events' });
  }

  putFileToS3PreSignedUrl(s3PreSignedURL, file) {
    return this.httpClient.put<any>(s3PreSignedURL, file, { headers: new HttpHeaders({ 'Content-Type': 'text/csv', 'Access-Control-Allow-Origin': window.location.origin, 'Access-Control-Allow-Methods': 'PUT' }) });
  }

  //This function will be deprecated soon. Don't use the variables populated in this function
  cacheSMSData(rs, storeData = true) {

    // Fallback logic to handle the scenario where currentUserData is null or undefined, preventing code breakage
    this.currentUserData = this.currentUserData ?? { userId: this.user.userId };

    if (rs.maxModifiedDt) this.maxModifiedDt = rs.maxModifiedDt;
    if (rs.skillHierarchy) this.skillHierarchy = rs.skillHierarchy;
    if (rs.skillCategories) this.skillCategories = rs.skillCategories;
    if (rs.smsSettings) Object.assign(this.client.smsSettings, rs.smsSettings);
    if (rs.skillItems) this.skillItems = rs.skillItems;
    if (rs.userCourses) this.currentUserData.userCourses = this.processUserCourses(rs.userCourses);
    if (rs.userSkillItems) this.currentUserData.userSkillItems = this.processUserSkillItems(rs.userSkillItems);
    if (rs.userSpecializations) this.currentUserData.userSpecializations = this.processAllSpecializations(rs.userSpecializations);
    if (rs.preSelectedSpecializationIds) this.currentUserData.preSelectedSpecializationIds = rs.preSelectedSpecializationIds;
    if (rs.preSelectedSpecializationCategories) this.currentUserData.preSelectedSpecializationCategories = rs.preSelectedSpecializationCategories;
    delete rs.userSkillItems;
    delete rs.userSpecializations;
    delete rs.userCourses;
    if (storeData) {
      if (rs.maxModifiedDt) {
        let { skillOrigins, skillHierarchyMaster } = this.normalizeSkillItems(rs.skillItems);
        localStorage.setItem('smsSkillsMaster', JSON.stringify({ maxModifiedDt: this.maxModifiedDt, skillHierarchy: this.skillHierarchy, skillCategories: this.skillCategories, skillOrigins: skillOrigins, skillHierarchyMaster: skillHierarchyMaster }));

      }
    }

  }
  normalizeSkillItems(data) {
    let skillOrigins = {}, skillItems = [], skillHierarchyMaster = {};
    if (!data) return { skillItems: skillItems, skillOrigins: skillOrigins, skillHierarchyMaster: skillHierarchyMaster };
    for (let id in data) {
      let d = data[id], o: any = {};
      Object.assign(o, d);
      o.categoryId = d.skillCategory.categoryId;
      o.skillHierarchy = d.skillHierarchy.map((h) => {
        if (h) {
          if (!skillHierarchyMaster[h?.id]) skillHierarchyMaster[h?.id] = h;
          return h.id;
        }
      });
      delete o.skillCategory;
      if (d.skillOrigin) {
        if (!skillOrigins[d.skillOrigin.id]) skillOrigins[d.skillOrigin.id] = d.skillOrigin;
        o.skillOriginId = d.skillOrigin.id;
        delete o.skillOrigin;
      }
      skillItems.push(o);
    }
    return { skillItems: skillItems, skillOrigins: skillOrigins, skillHierarchyMaster: skillHierarchyMaster };
  }
  deNormalizeSkillItems(data, skillCategories, skillHierarchyMaster, skillOrigins) {
    let skillItems: any = {};
    let arr: any[] = this.deCompressDataArray(data);
    arr.map((d) => {
      d.skillCategory = skillCategories.filter((c) => c.categoryId == d.categoryId)[0];
      if (d.skillOriginId) d.skillOrigin = skillOrigins[d.skillOriginId];
      delete d.categoryId;
      delete d.skillOriginId;
      let h = [];
      d.skillHierarchy.map((id) => { if (skillHierarchyMaster[id]) h.push(JSON.parse(JSON.stringify(skillHierarchyMaster[id]))) });
      d.skillHierarchy = h;
      skillItems[d.skillItemId] = d;
    });
    return skillItems;
  }
  compressDataArray(data) {
    let keyHash = {}, cnt = 0, arr = [];
    data.map((d) => {
      let o = {};
      for (let key in d) {
        if (!keyHash[key]) keyHash[key] = 'k' + (cnt++);
        o[keyHash[key]] = d[key];
      }
      arr.push(o);
    })
    return { data: arr, keyHash: keyHash };
  }
  deCompressDataArray(data) {
    let arr = [], hash = {};
    for (let key in data.keyHash) hash[data.keyHash[key]] = key;
    data.data.map((d) => {
      let o = {};
      for (let key in d) o[hash[key]] = d[key];
      arr.push(o);
    })
    return arr;
  }

  readSMSData() {
    try {
      let rs = JSON.parse(localStorage.getItem("smsSkillsMaster"));
      let skillItems = JSON.parse(localStorage.getItem("skillItems"));
      if (skillItems) localStorage.removeItem('skillItems');
      this.cacheSMSData(rs, false);
    } catch (err) {
      try {
        let rs = JSON.parse(localStorage.getItem("smsSkillsMaster"));
        let skillItems = JSON.parse(sessionStorage.getItem("skillItems"));
        if (skillItems) sessionStorage.removeItem('skillItems')
        this.cacheSMSData(rs, false);
      } catch (e) { }
    }
    try { this.cacheSMSData(JSON.parse(localStorage.getItem("smsSpecsData")) || null), false; } catch (err) { }
    try { this.cacheSMSData(JSON.parse(localStorage.getItem("smsCoursesData")), false); } catch (err) { }
    try { this.smsCache = JSON.parse(sessionStorage.getItem("smsCache")) || {}; } catch (err) { }
  }

  //Pre-processing functions
  processAllCourses(courses) {
    courses.map((c) => {
      c.name = c.courseName;
      c.search = c.courseName + (c.courseType ? ' ' + '(' + c.courseType + ')' : '');
      c.bg = 'bg' + (c.name.toLowerCase().split('').map((s) => { return s.charCodeAt(0) }).reduce((a, c) => { return a + c }) % 22);
      (c.skillItems || []).map((skillItem) => {
        if (!skillItem) return;
        c.search += ' ' + skillItem.name;
      }).filter(skillItem => skillItem);
      if (!c.description) c.description = c.courseDescription ? c.courseDescription : ''
      c.skillItems?.sort((a, b) => { return a?.name > b?.name ? 1 : a?.name < b?.name ? -1 : 0 });
      c.resultStatus = c.resultStatus ? c.resultStatus.toUpperCase(): '';
      return c;
    })
    return courses;
  }
  processAllSpecializations(specs) {
    specs.map((s) => {
      if (!s.name) return null;
      s.bg = 'bg' + (s.name.toLowerCase().split('').map((s) => { return s.charCodeAt(0) }).reduce((a, c) => { return a + c }) % 22);
      s.search = s.name;
      if (!s.logo && s.program && s.program.logox512) {
        s.logo = s.program.logox512
      }
      (s.skillItems || []).map((skillItem) => {
        if (skillItem.name) s.search += " " + skillItem.name;
      })
    });
    specs.sort((a, b) => { return a.trendScore > b.trendScore ? -1 : a.trendScore < b.trendScore ? 1 : a.name > b.name ? 1 : a.name < b.name ? -1 : 0 });
    return specs;
  }
  processUserSkillItems(userSkillItems) {
    this.currentUserData.requiredRatingHash = {};
    let designation = this.currentUserData && this.currentUserData.userId != this.user.userId && this.currentUserData.designationObject ? this.currentUserData.designationObject : this.user.designationObject;
    this.client.smsSettings.categoryWiseSettings.map((item) => {
      if (item.autoPopulateSkillTagging != 'Yes' || (item.autoPopulateSkillTaggingCriteria || []).indexOf('Designation') < 0) return;
      if (designation) (designation['skillItemIds_' + item.categoryId] || []).map((requiredRatingItem) => { this.currentUserData.requiredRatingHash[requiredRatingItem.skillItemId] = requiredRatingItem.requiredRating });
    })
    for (let i = 0; i < userSkillItems?.length; i++) {
      let skillItem = userSkillItems[i];
      if (skillItem.skillTagId) {
        let settings = this.client.smsSettings.categoryWiseSettings.filter((item) => item.categoryId == skillItem.skillCategoryId)[0];
        if (settings.autoPopulateSkillTagging == 'Yes') skillItem.skillTag = settings.skillTags.filter((item) => item.skillTagId == skillItem.skillTagId)[0];
      }
      if (skillItem.hideSkill == 'Yes') { userSkillItems.splice(i--, 1); continue; }
      skillItem.requiredRating = this.currentUserData.requiredRatingHash[skillItem.skillItemId] || 0;
    }
    return userSkillItems;
  }
  processUserCourses(courses) {
    courses.map((c) => { Object.assign(c, c.course) })
    this.processAllCourses(courses);
    courses.sort((a, b) => { return a.trendScore > b.trendScore ? -1 : a.trendScore < b.trendScore ? 1 : a.name > b.name ? 1 : a.name < b.name ? -1 : 0 });
    return courses;
  }
  processFilterData(filters, stopCache = false) {
    const filterData: any = { _titles: filters._titles, _sequence: filters._sequence, _hrmssequence: filters._hrmssequence };
    const fieldMap = {};
    let cnt = 0;
    const idHash = {};
    const getId = () => { let s = ''; while (!s || idHash[s]) s = Math.round(Math.random() * 1000000) + ''; return s; }
    const sequence = (filterData?._hrmssequence || []).concat(filterData?._sequence || []);
    sequence.map((key) => {
      if (!filters[key]) return;
      if (!fieldMap[key]) {
        fieldMap[key] = 'field' + cnt++;
      }
      let mapKey = fieldMap[key];
      if (!filterData[mapKey]) filterData[mapKey] = [];
      filters[key].map((v) => {
        if (key == 'skills') {
          let o: any = { id: v, key: key, inputId: getId() };
          if (this.skillItems[o.id] && this.skillItems[o.id].hideSkill != 'Yes') {
            Object.assign(o, this.skillItems[o.id]);
            o.search = o.name + (o.skillHierarchy[1] ? o.skillHierarchy[1].name : '') + ' ' + (o.skillHierarchy[2] ? o.skillHierarchy[2].name : '');
            o.skillHierarchyName = (o.skillHierarchy[1] ? o.skillHierarchy[1].name : '');
            o.value = o.name;
            filterData[mapKey].push(o);
          }
        } else if (key == 'projects') {
          let o: any = v;
          Object.assign(o, { key: key, inputId: getId(), projectSkillSearch: '' })
          o.skillItemIds.map((skillItemId) => {
            if (this.skillItems[skillItemId]) o.projectSkillsSearch += ' ' + this.skillItems[skillItemId].name;
          })
          o.search = o.value + ' ' + o.projectSkillsSearch;
          filterData[mapKey].push(o);
        } else if (v && v.projectIds) {
          let o: any = v;
          Object.assign(o, { key: key, inputId: getId(), search: v.value })
          filterData[mapKey].push(o);
        } else {
          filterData[mapKey].push({ key: key, value: v, inputId: getId(), search: v });
        }
        filterData[mapKey].sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0);
      })
    });
    ['fromDt', 'toDt', 'minFromDt', 'maxToDt', 'useQuarters'].map((key) => filterData[key] = filters[key]);
    filterData._fieldMap = fieldMap;
    if (!stopCache) this.smsCache.dbFilterData = filterData;
    if (!stopCache) this.smsCache.dbFilterDataTime = moment();
    if (filters?.specialization)
      filterData.specialization = filters.specialization;
    const allSkillCluster = this.client?.smsSettings?.specializationMode === SMS_OPTIONS.SKILL_CLUSTER_BASED
                            && this.client?.smsSettings?.useSkillClusterTags === SMS_OPTIONS.YES;
    if (filters.skillCluster && allSkillCluster)
      filterData.skillCluster = filters.skillCluster;
    return filterData;
  }

  processMiFilterData(filters) {

    let fd: any = { _titles: filters._titles, _sequence: filters._sequence }, fieldMap = {}, cnt = 0;
    let idHash = {};
    const getId = () => { let s = ''; while (!s || idHash[s]) s = Math.round(Math.random() * 1000000) + ''; return s; }
    filters._sequence.map((key) => {
      if (!filters[key]) return;
      if (!fieldMap[key]) {
        fieldMap[key] = 'field' + cnt++;
      }
      let mapKey = fieldMap[key];
      if (!fd[mapKey]) fd[mapKey] = [];
      filters[key].map((v) => {
        if (key == 'skills') {
          let o: any = { id: v, key: key, inputId: getId() };
          if (this.skillItems[o.id] && this.skillItems[o.id].hideSkill != 'Yes') {
            Object.assign(o, this.skillItems[o.id]);
            o.search = o.name + (o.skillHierarchy[1] ? o.skillHierarchy[1].name : '') + ' ' + (o.skillHierarchy[2] ? o.skillHierarchy[2].name : '');
            o.skillHierarchyName = (o.skillHierarchy[1] ? o.skillHierarchy[1].name : '');
            o.value = o.name;
            fd[mapKey].push(o);
          }
        } else if (v && v.projectIds) {
          let o: any = v;
          Object.assign(o, { key: key, inputId: getId(), search: v.value })
          fd[mapKey].push(o);
        } else {
          fd[mapKey].push({ key: key, value: v, inputId: getId(), search: v });
        }
        fd[mapKey].sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0);
      })
    });
    ['fromDt', 'toDt', 'minFromDt', 'maxToDt', 'useQuarters'].map((key) => fd[key] = filters[key]);
    fd._fieldMap = fieldMap;
    this.smsCache.miFilterData = fd;
    this.smsCache.miFilterDataTime = moment();
    return fd;
  }
}
