import { Injectable } from '@angular/core';
import { CoralogixRum } from '@coralogix/browser';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CoralogixService {

  constructor(
  ) { }
  
  public setUserContext(user: User) {
    CoralogixRum.setUserContext({
      user_id: user.userId,
      user_name: user.name,
      user_email: user.email,
    });
  }
  
  //This function can be used for sending other details to Coralogix if needed
  /* private setLabels() {
    CoralogixRum.setLabels({
      paymentMethod: 'visa',
      userTheme: 'black',
    });
  } */

}
